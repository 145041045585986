<template>
  <div class="product-page">
    <div class="intro">
      <div class="title-line">
        <h3 class="page-title">Modern Halo — Not Just a Product But an Experience</h3>
        <h3 class="price">$599</h3>
      </div>

      <!-- Carousel Component -->
      <!-- Source document: https://ismail9k.github.io/vue3-carousel/ -->
      <Carousel :autoplay="3500" :wrap-around="true" :pause-autoplay-on-hover="true">
        <Slide v-for="item in figures" :key="item.id" class="carousel-slide">
          <div>
            <img :src="'./images/' + item.image" class="slide-image-figure" />
          </div>
          <div v-if="item.id==='i1'" class="slide-desc">
            <p>
              If you are thinking about living the smart life you deserve, this easy-installation smart toilet seat is a perfect bridge between opulence and practicality, 
              representing a harmonious blend of sophistication and functionality. 
            </p>
            <p>
              It's designed for those who yearn for a touch of luxury but may not desire the full suite of a smart toilet.    
            </p>
          </div>
          <div v-if="item.id==='i2'" class="slide-desc">
            <h5 class="slide-desc-title">Smart Technologies</h5>
            <ul>
              <li style="display: list-item;">Bidet false-trigger prevention</li>
              <li style="display: list-item;">Remote control</li>
              <li style="display: list-item;">Eco-friendly power-saving</li>
              <li style="display: list-item;">Power leakage protection</li>
            </ul>
          </div>
          <div v-if="item.id==='i3'" class="slide-desc">
            <h5 class="slide-desc-title">Comprehensive Ergonomics</h5>
            <ul>
              <li style="display: list-item;">Bidet warm wash</li>
              <li style="display: list-item;">Adjustable water jet pressure</li>
              <li style="display: list-item;">Aerator integrated</li>
              <li style="display: list-item;">Swing water jet</li>
              <li style="display: list-item;">Warm air dry</li>
              <li style="display: list-item;">Heated seat</li>
              <li style="display: list-item;">Ergonomic seat contour</li>
              <li style="display: list-item;">Adjustable seat position</li>
              <li style="display: list-item;">Slow-close seat and cover</li>
            </ul>
          </div>
          <div v-if="item.id==='i4'" class="slide-desc">
            <h5 class="slide-desc-title">Microscopic-Level Hygiene</h5>
            <ul>
              <li style="display: list-item;">Automatic deodorization</li>
              <li style="display: list-item;">3-in-1 self-cleansing antimicrobial nozzle</li>
              <li style="display: list-item;">Antimicrobial seat</li>
            </ul>
          </div>
        </Slide>

        <template #addons>
          <Pagination />
        </template>
      </Carousel>

      <!--p>
        If you are thinking about living the smart life you deserve, this is a perfect bridge between opulence and practicality, 
        representing a harmonious blend of sophistication and functionality. 
        It's designed for those who yearn for a touch of luxury but may not desire the full suite of a smart toilet.    
      </p>
      <p>
        Exuding a refined and delicate aura, every feature and every contour of the seat has been meticulously engineered with <strong>ergonomics</strong> in mind. 
        The adaptive warmth of water, air, and seat, tuneable water pressure, and a choice between aerated spray, sprinkle, or jet water streams — 
        all are implemented with precision to match individual comfort levels.
      </p>
      <p>
        Applying <strong>antimicrobial materials and self-cleansing technology</strong>, its three-in-one nozzle promises peace of mind in terms of hygiene and sanitation. 
        Prioritizing safety, the seat is fortified against electrical leakage, pressure surges, overheating, false triggers, and more.     
      </p-->
      
      <!--div class="clear"></div-->

      <h4 class="spec-title">Features and Specifications</h4>
    </div>

    <div class="spec">
      <table>
        <!-- Bidet (Femine Warm Wash, Posterior Warm Wash) -->		
        <tr><th colspan="2">Bidet Warm Wash</th></tr>
        <tr><td>	Faminine wash	    </td><td class="tick">&#10003;</td></tr>
        <tr><td>	Posterior wash    </td><td class="tick">&#10003;</td></tr>
        <tr><td>	Water temperature </td><td class="tick">4-level adjustable</td></tr>
        <tr><td>	Water pressure    </td><td class="tick">10 level adjustable, jet</td></tr>
        <tr><td>	Washing angle     </td><td class="tick">oscillating nozzle wand</td></tr>
        <tr><td>	Nozzle	          </td><td class="tick">feminine wash, posterior wash, and jet 3-in-1</td></tr>
        <tr><td>	Aerator	          </td><td class="tick">integrated</td></tr>
        <!-- Warm Air Dry -->		
        <tr><th colspan="2">Warm Air Dry</th></tr>
        <tr><td>	Air temperature 	</td><td class="tick">4-level adjustable</td></tr>
        <!-- Heated Seat -->		
        <tr><th colspan="2">Heated Seat</th></tr>
        <tr><td>	Seat temperature</td><td class="tick">4-level adjustable</td></tr>
        <tr><td>	Slow-close	    </td><td class="tick">damping</td></tr>
        <tr><td>	Contour	        </td><td class="tick">ergonomic</td></tr>
        <tr><td>	Seating position</td><td class="tick">adjustable</td></tr>
        <!-- Hygiene -->		
        <tr><th colspan="2">Hygiene and Sanitation</th></tr>
        <tr><td>	Automatic deodorization </td><td class="tick">&#10003;</td></tr>
        <tr><td>	Nozzle self-cleansing	  </td><td class="tick">&#10003;</td></tr>
        <tr><td>	Antimicrobial nozzle	  </td><td class="tick">&#10003;</td></tr>
        <tr><td>	Replaceable nozzle	    </td><td class="tick">&#10003;</td></tr>
        <tr><td>	Antimicrobial seat	    </td><td class="tick">&#10003;</td></tr>
        <!-- Safety -->		
        <tr><th colspan="2">Safety</th></tr>
        <tr><td>	False-trigger prevention      </td><td class="tick">smart seating sensor</td></tr>
        <tr><td>	Electrical leakage protection	</td><td class="tick">&#10003;</td></tr>
        <tr><td>	Certification 	              </td><td class="tick">cUPC, UL</td></tr>
        <tr><td>	Water resistance	            </td><td class="tick">IPX4</td></tr>
        <tr><td>	Night light	                  </td><td class="tick">&#10003;</td></tr>
        <!-- Sensor & User Interaction -->		
        <tr><th colspan="2">Sensor & User Interaction</th></tr>
        <tr><td>	Seating sensor      </td><td class="tick">&#10003;</td></tr>
        <tr><td>	Side button control	</td><td class="tick">&#10003;</td></tr>
        <tr><td>	Remote control	    </td><td class="tick">&#10003;</td></tr>
        <!-- Intelligence -->		
        <tr><th colspan="2">Intelligence</th></tr>
        <tr><td>	Automation	      </td><td class="tick">deodorization</td></tr>
        <tr><td>	Smart power saving</td><td class="tick">&#10003;</td></tr>
        <!-- Physics -->		
        <tr class="category"><th colspan="2">	Physics	</th></tr>
        <tr><td>	Width	              </td><td class="tick">387mm / 15&frac14;"</td></tr>
        <tr><td>	Depth	              </td><td class="tick">521mm / 20&frac12;"</td></tr>
        <tr><td>	Max thickness       </td><td class="tick">146mm / &nbsp;5&frac34;"</td></tr>
        <tr><td>	Rated water pressure</td><td class="tick">0.15-0.75MPa</td></tr>
        <tr><td>	Rated voltage	      </td><td class="tick">AC120V 60Hz</td></tr>
        <tr><td>	Rated power	        </td><td class="tick">1000W</td></tr>
      </table>

      <!-- Carousel Component -->
      <!-- Source document: https://ismail9k.github.io/vue3-carousel/ -->
      <Carousel v-bind="settings" :breakpoints="featureBreak" :autoplay="2300" :wrap-around="true" :pause-autoplay-on-hover="true">
        <Slide v-for="item in features" :key="item.id" class="carousel-slide">
          <div>
            <img :src="'./images/' + item.image" class="slide-image-feature" />
            <h6 class="slide-caption">{{ item.name }}</h6>
          </div>
        </Slide>

        <template #addons>
          <Navigation />
        </template>
      </Carousel>
    </div>

    <button @click="$emit('back')" class="btn-porcelain" style="padding: 5px 10px; margin: 20px;">Back to products</button>
 
  </div>
</template>

<script>

import "vue3-carousel/dist/carousel.css";  // source: https://ismail9k.github.io/vue3-carousel/
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation, Pagination } from 'vue3-carousel';
import '@/components/carousel.css';
import '@/allpage.css';

export default defineComponent({
  name: 'SmartSeatPage',
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  components: {
    Carousel,
    Slide,
    Navigation,
    Pagination,
  },
  data() {
    return {
      figures: [
        { id: "i1", name: "Top", image: "pic_ak6607v_1.png" },
        { id: "i2", name: "Side", image: "pic_ak6607v_2.png" },
        { id: "i3", name: "Side Control", image: "pic_ak6607v_5.png" },
        { id: "i4", name: "Damping Drop", image: "pic_ak6607v_3.png" }
      ],
      features: [
        { id: "p3", name: "Side Control", image: "pic_ak6607v_6.png" },
        { id: "p4", name: "Damping Drop", image: "pic_ak6607v_5.png" },
        { id: "p5", name: "Posterior Wash", image: "ft_cleansing_3.png" },
        { id: "p6", name: "Feminine Wash", image: "ft_cleansing_4.png" },
        { id: "p7", name: "Warm Dry", image: "ft_dry_2.png" },
        { id: "p8", name: "Seating Sensor", image: "ft_sensing_2.png" },
        { id: "p9", name: "Heated Seat", image: "ft_amenity_1.png" },
        { id: "p10", name: "Ergonomic Seat", image: "ft_ergonomic.png" },
        { id: "p11", name: "Self-Clean", image: "ft_sanitary_3.png" }
      ],
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      featureBreak: {
        // 800px and up
        800: {
          itemsToShow: 3,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: 'start',
        },
        // 1920px and up
        1920: {
          itemsToShow: 7,
          snapAlign: 'start',
        },
      },
    };
  },
})
</script>

<style scoped>
  /* for feature and spec table */
  table {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
      border-collapse: collapse;
      text-align: left;
  }

  th {
      border: 1px solid #ccc;
      padding: 5px 10px;
      background-color: #f2f2f2;
      font-weight: bold;
  }

  td {
      border: 1px solid #ccc;
      padding: 5px 10px 5px 30px;
  }

  td.tick {
      color: green;
      padding: 5px 10px;
      text-align: center;
  }

@media screen and (max-width: 1023px) {
  th {
      padding-left: 8px;
  }

  td {
    padding: 5px 5px 5px 16px;
  }

  td.tick {
    padding: 5px;
  }
}
</style>
